import { API } from '../utils/api';

export const SET_DECKS = 'SET_DECKS';

export function listDecks () {
  return async (dispatch) => {
    return API.get('/decks')
    .then((decks) => {
      dispatch(setDecks(decks));
    });
  };
}

export function setDecks (decks) {
  return { type: SET_DECKS, decks };
}
