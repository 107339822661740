import ReCAPTCHA                                                 from 'react-google-recaptcha';
import jsonp                                                     from 'jsonp';
import { FontAwesomeIcon }                                       from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useRef, useState }                 from 'react';
import { faAsterisk, faCloud, faCogs, faFileImport, faShareAlt } from '@fortawesome/free-solid-svg-icons';

import { Config }               from '../config';
import { Flash }                from './flash';
import { Footer }               from './footer';
import { Nav }                  from './nav';
import { ReactGA, logPageView } from '../utils/analytics';

export const Home = () => {
  const recaptchaRef = useRef();

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'Online Spaced Repetition Flashcards | CoreSRS';
    logPageView();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSuccess(null);
    setError(null);

    recaptchaRef.current.executeAsync()
    .then((token) => {
      if (!token) {
        throw new Error('Looks like something went wrong. Refresh and try again!');
      }

      const url = `${Config.MAILCHIMP_URL}&EMAIL=${encodeURIComponent(email)}`;
      return new Promise((resolve, reject) => {
        jsonp(url, { param: 'c' }, (err, data) => {
          if (err) {
            return reject(err);
          }
          resolve(data);
        });
      });
    })
    .then((data) => {
      if (data.msg.includes('already subscribed')) {
        setSuccess('Looks like you\'re already subscribed!');
        ReactGA.event({ action: 'joined', category: 'Waitlist', label: 'duplicate' });
      } else if (data.result !== 'success') {
        throw new Error(data.msg.replace(/^\d+ - /, ''));
      } else {
        setSuccess('You\'ve successfully joined the waitlist! Stay tuned!');
        ReactGA.event({ action: 'joined', category: 'Waitlist', label: 'success' });
      }
    })
    .catch((err) => {
      if (err && err.message) {
        setError(err.message);
        ReactGA.event({ action: 'error', category: 'Waitlist', label: err.message });
      } else {
        setError('Unexpected error! Try again!');
        ReactGA.event({ action: 'error', category: 'Waitlist', label: err });
      }
    });
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  return (
    <Fragment>
      <main className="home-container">
        <div className="home">
          <div className="hero-container">
            <Nav />
            <div className="hero">
              <h1>CoreSRS</h1>
              <h2>Online Spaced Repetition Flashcards</h2>
            </div>
          </div>
        </div>
        <div className="feature-container container">
          <section>
            <div className="feature-header">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faCogs} />
              </div>
              <h3>Customizable, but works out-of-the-box</h3>
            </div>
            <p>
              You&apos;re able to customize all aspects of your flashcard decks,
              including the fields each card has, how the front and the back of the
              card looks, whether you type in the answer or just flip the card, and
              many more! But for those that just want to get to studying as fast as
              possible, it&apos;s really easy to create a new deck and start adding
              cards.
            </p>
          </section>
          <section>
            <div className="feature-header">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faCloud} />
              </div>
              <h3>Built for the web</h3>
            </div>
            <p>
              CoreSRS is built to be 100% online so that you can access it from any
              device that can connect to the internet. Whether it&apos;s to add new
              cards or stay on top of your reviews, you can do it from anywhere.
              All of your progress is also stored in the cloud, so you don&apos;t ave to
              worry about losing any of that information.
            </p>
          </section>
          <section>
            <div className="feature-header">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faFileImport} />
              </div>
              <h3>Compatible with Anki decks</h3>
            </div>
            <p>
              Already have some Anki decks that you&apos;re learning from? Easily import
              any .apkg files that you have exported and bring in all of the cards
              that you know and love. And we&apos;ll also import your progress so that
              you don&apos;t have to start from scratch. And if you decide to stop using
              CoreSRS, you can export your progress and import it back into Anki.
            </p>
          </section>
          <section>
            <div className="feature-header">
              <div className="feature-icon">
                <FontAwesomeIcon icon={faShareAlt} />
              </div>
              <h3>Easily sharable with everyone</h3>
            </div>
            <p>
              Decks are meant to be shared! As a deck creator, you&apos;ll have
              the option make any deck public and searchable in our deck gallery.
              And if you decide to use a public deck, you can either stay connected
              to the original so that you can get any changes the creator makes, or
              you can clone it so that you can tweak it to your liking.
            </p>
          </section>
        </div>
        <div className="email-section">
          <div className="email-container container">
            <h4>Want to join the waitlist for the beta?</h4>
            <p>Enter your email to join the waitlist, and when we get ready to have beta testers, we&apos;ll reach out to you first!</p>
            <Flash show={Boolean(success)} type="success">
              {success}
            </Flash>
            <Flash show={Boolean(error)} type="error">
              {error}
            </Flash>
            <form className="center-form" onSubmit={handleSubmit}>
              <div className="form-group">
          	    <label htmlFor="email">
                  Email
                  <FontAwesomeIcon icon={faAsterisk} />
                </label>
          	    <input
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleEmailChange}
                  placeholder="you@gmail.com"
                  required
                  type="email"
                  value={email}
                />
              </div>
              <div className="form-group">
                <button type="submit">Join!</button>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={Config.RECAPTCHA_SITE_KEY}
                size="invisible"
              />
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};
