import { API } from '../utils/api';

export const SET_CARDS = 'SET_CARDS';

export function listCards (params) {
  return async (dispatch) => {
    return API.get('/cards', { params })
    .then((cards) => {
      dispatch(setCards(cards));
    });
  };
}

export function setCards (cards) {
  return { type: SET_CARDS, cards };
}
