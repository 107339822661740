import Mustache  from 'mustache';
import PropTypes from 'prop-types';
import root      from 'react-shadow';

const front = '{{Kanji}}';

const styles = `.card {
  font-family: arial;
  font-size: 20px;
  text-align: center;
  color: black;
  background-color: white;
}`;

const back = `{{FrontSide}}

<hr id=answer>

{{Kana}}
<br>
{{English}}`;

export const Card = ({ card }) => {
  const renderedFront = Mustache.render(front, card.note.fields);
  const renderedBack = Mustache.render(back, { ...card.note.fields, FrontSide: renderedFront });

  return (
    <div className="card">
      <root.div className="quote">
        <div className="card front" dangerouslySetInnerHTML={{ __html: renderedFront }} />
        <div className="card back" dangerouslySetInnerHTML={{ __html: renderedBack }} />
        <style type="text/css">{styles}</style>
      </root.div>
    </div>
  );
};

Card.propTypes = {
  card: PropTypes.object.isRequired
};
