import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter }       from '@fortawesome/free-brands-svg-icons';
import { useMemo }         from 'react';

export const Footer = () => {
  const year = useMemo(() => (new Date()).getFullYear(), []);

  return (
    <footer>
      <span>&copy; {year} CoreSRS</span>
      <a href="https://twitter.com/coresrs" rel="noopener noreferrer" target="_blank">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </footer>
  );
};
