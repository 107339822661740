import { SET_CARDS } from '../actions/cards';

export function cards (state = {}, action) {
  switch (action.type) {
    case SET_CARDS:
      return action.cards;
    default:
      return state;
  }
}
