import axios from 'axios';

import { Config } from '../config';

export const API = axios.create({
  baseURL: Config.API_URL,
  withCredentials: true
});

API.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  if (!error.response || !error.response.data || !error.response.data.error) {
    return Promise.reject(error);
  }

  const err = new Error(error.response.data.error.message);
  err.code = error.response.data.error.code;
  err.status_code = error.response.data.error.status_code;
  err.response = error.response;

  return Promise.reject(err);
});
