import { combineReducers } from 'redux';

import { cards }   from './cards';
import { decks }   from './decks';
import { session } from './session';

export const Reducer = combineReducers({
  cards,
  decks,
  session
});
