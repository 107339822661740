import { Fragment, useEffect } from 'react';

import { Footer }      from './footer';
import { Nav }         from './nav';
import { logPageView } from '../utils/analytics';

export const NotFound = () => {
  useEffect(() => {
    document.title = 'Not Found | CoreSRS';
    logPageView();
  }, []);

  return (
    <Fragment>
      <Nav />
      <main className="container">
        <h1>Whoops, looks like I forgot about that page!</h1>
      </main>
      <Footer />
    </Fragment>
  );
};
