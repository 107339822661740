import { FontAwesomeIcon }               from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { Link }                          from 'react-router-dom';
import { faAsterisk }                    from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector }      from 'react-redux';
import { useHistory }                    from 'react-router';

import { Flash }       from './flash';
import { Footer }      from './footer';
import { Nav }         from './nav';
import { logPageView } from '../utils/analytics';
import { register }    from '../actions/session';

export const Register = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const session = useSelector(({ session }) => session);

  const [error, setError] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  useEffect(() => {
    document.title = 'Register | CoreSRS';
    logPageView();
  }, []);

  useEffect(() => {
    if (session) {
      history.push('/decks');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setError('Passwords need to match.');
      return;
    }

    setError(null);

    dispatch(register({ username, email, password }))
    .then(() => {
      history.push('/decks');
    })
    .catch((err) => {
      setError(err.message);
      window.scrollTo({ top: 0 });
    });
  };

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handlePasswordConfirmChange = (e) => setPasswordConfirm(e.target.value);

  return (
    <Fragment>
      <Nav />
      <main>
        <form className="center-form" onSubmit={handleSubmit}>
          <h1>Register</h1>
          <Flash show={Boolean(error)} type="error">
            {error}
          </Flash>
          <div className="form-group">
            <label htmlFor="username">
              Username
              <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className="form-control"
              id="username"
              name="username"
              onChange={handleUsernameChange}
              placeholder="username12"
              required
              spellCheck="false"
              type="text"
              value={username}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email
              <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              className="form-control"
              id="email"
              name="email"
              onChange={handleEmailChange}
              placeholder="you@gmail.com"
              required
              spellCheck="false"
              type="email"
              value={email}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">
              Password
              <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              className="form-control"
              id="password"
              name="password"
              onChange={handlePasswordChange}
              placeholder="••••••••••••"
              required
              type="password"
              value={password}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password-confirm">
              Confirm Password
              <FontAwesomeIcon icon={faAsterisk} />
            </label>
            <input
              className="form-control"
              id="password-confirm"
              name="password-confirm"
              onChange={handlePasswordConfirmChange}
              placeholder="••••••••••••"
              required
              type="password"
              value={passwordConfirm}
            />
          </div>
          <div className="form-group">
            <button className="btn btn-blue" type="submit">Get Started</button>
            <p>Already have an account? <Link className="link" to="/login">Login here</Link>!</p>
          </div>
        </form>
      </main>
      <Footer />
    </Fragment>
  );
};
