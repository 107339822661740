import './styles/index.scss';

import { createBrowserHistory } from 'history';
import { render }               from 'react-dom';
import { Router }               from 'react-router-dom';
import { Provider }             from 'react-redux';

import { App }   from './components/app';
import { Store } from './stores';

const history = createBrowserHistory();

render(
  <Provider store={Store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
