import { Redirect, Route }          from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState }      from 'react';

import { retrieveSession } from '../actions/session';

export const SecureRoute = (props) => {
  const dispatch = useDispatch();

  const session = useSelector(({ session }) => session);

  const [render, setRender] = useState(false);

  useEffect(() => {
    dispatch(retrieveSession())
    .catch(() => { })
    .finally(() => {
      setRender(true);
    });
  }, []);

  if (!render) {
    return null;
  }

  if (!session) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
