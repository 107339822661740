import { Link } from 'react-router-dom';

export const Nav = () => {
  // const dispatch = useDispatch();

  // const history = useHistory();

  // const session = useSelector(({ session }) => session);

  // const handleLogout = (e) => {
  //   e.preventDefault();

  //   dispatch(logout())
  //   .then(() => {
  //     history.push('/login');
  //   });
  // };

  return (
    <nav>
      <div className="container">
        <div className="section logo">
          <Link to="/"><span>CoreSRS</span></Link>
        </div>
        {/* <div className="section">
          {session ?
            <Fragment>
              <a href="#" onClick={handleLogout}><span>Logout</span></a>
            </Fragment> :
            <Fragment>
              <Link to="/login"><span>Login</span></Link>
              <Link to="/register"><span>Register</span></Link>
            </Fragment>
          }
        </div> */}
      </div>
    </nav>
  );
};
