import { SET_DECKS } from '../actions/decks';

export function decks (state = {}, action) {
  switch (action.type) {
    case SET_DECKS:
      return action.decks;
    default:
      return state;
  }
}
