import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector }      from 'react-redux';

import { Card }        from './card';
import { Footer }      from './footer';
import { Nav }         from './nav';
import { listCards }   from '../actions/cards';
import { logPageView } from '../utils/analytics';
import { useQuery }    from '../hooks/use-query';

export const Review = () => {
  const dispatch = useDispatch();

  const { deck, state } = useQuery();

  const cards = useSelector(({ cards }) => cards);

  const [index] = useState(0);

  useEffect(() => {
    if (state === 'new') {
      document.title = 'Learn | CoreSRS';
    } else {
      document.title = 'Review | CoreSRS';
    }
    logPageView();
  }, []);

  useEffect(() => {
    dispatch(listCards({ deck_id: deck, state }))
    .catch((err) => {
      // TODO: handle error
      console.log(err); // eslint-disable-line
    });
  }, []);

  if (!cards.cards) {
    // TODO: add loading spinner
    return null;
  }

  return (
    <Fragment>
      <Nav />
      <main>
        <h1>Review</h1>
        <Card card={cards.cards[index]} />
      </main>
      <Footer />
    </Fragment>
  );
};
