import { Route, Switch } from 'react-router-dom';

import { Decks }       from './decks';
import { Home }        from './home';
import { Login }       from './login';
import { NotFound }    from './not-found';
import { Register }    from './register';
import { Review }      from './review';
import { SecureRoute } from './secure-route';

export const App = () => (
  <Switch>
    <Route component={Home} exact path="/" />
    <Route component={Login} path="/login" />
    <Route component={Register} path="/register" />
    <SecureRoute component={Decks} path="/decks" />
    <SecureRoute component={Review} path="/review" />
    <Route component={NotFound} path="/" />
  </Switch>
);
