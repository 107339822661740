import { API } from '../utils/api';

export const SET_SESSION = 'SET_SESSION';

export function retrieveSession () {
  return async (dispatch) => {
    return API.get('/session')
    .then((session) => {
      dispatch(setSession(session));
    });
  };
}

export function register ({ username, email, password }) {
  return (dispatch) => {
    return API.post('/register', { username, email, password })
    .then((session) => {
      dispatch(setSession(session));
    });
  };
}

export function login ({ username, password }) {
  return (dispatch) => {
    return API.post('/login', { username, password })
    .then((session) => {
      dispatch(setSession(session));
    });
  };
}

export function logout () {
  return (dispatch) => {
    return API.post('/logout')
    .then(() => {
      dispatch(setSession(null));
    });
  };
}

export function setSession (session) {
  return { type: SET_SESSION, session };
}
