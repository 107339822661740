import PropTypes from 'prop-types';

export const Flash = ({ children, show, type }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={`flash flash-${type}`}>
      {children}
    </div>
  );
};

Flash.propTypes = {
  children: PropTypes.any,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['error', 'success']).isRequired
};
