import Moment                       from 'moment';
import { Fragment, useEffect }      from 'react';
import { Link }                     from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Footer }      from './footer';
import { Nav }         from './nav';
import { listDecks }   from '../actions/decks';
import { logPageView } from '../utils/analytics';

export const Decks = () => {
  const dispatch = useDispatch();

  const decks = useSelector(({ decks }) => decks);

  useEffect(() => {
    document.title = 'Decks | CoreSRS';
    logPageView();
  }, []);

  useEffect(() => {
    dispatch(listDecks())
    .catch((err) => {
      // TODO: handle error
      console.log(err); // eslint-disable-line
    });
  }, []);

  if (!decks.decks) {
    // TODO: add loading spinner
    return null;
  }

  return (
    <Fragment>
      <Nav />
      <main className="container">
        <h1>Decks</h1>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Created At</th>
              <th>New Cards</th>
              <th>Cards to Review</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {decks.decks.map((deck) => (
              <tr key={deck.id}>
                <td>{deck.name}</td>
                <td>{Moment(deck.created_at).format('LL')}</td>
                <td>{deck.new}</td>
                <td>{deck.review}</td>
                <td className="actions">
                  <Link to={`/review?deck=${deck.id}&state=new`}><button>Learn</button></Link>
                  <Link to={`/review?deck=${deck.id}&state=review`}><button>Review</button></Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
      <Footer />
    </Fragment>
  );
};
